import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Admin/Login/Login";
import AdminHomePage from "./Pages/Admin/AdminHomePage/AdminHomePage";
import TextEditor from "./Pages/Admin/TextEditor/TextEditor";
import ManageEditions from "./Pages/Admin/EditionEditor/ManageEditions";
import Register from "./Pages/Admin/Register/Register";
import ManageUsers from "./Pages/Admin/ManageUsers/ManageUsers";
import Eye from "./Components/Eye/Eye";
import Recovery from "./Pages/Recovery/Recovery";
import EditEdition from "./Pages/Admin/EditEdition/EditEdition";
import NewEdition from "./Pages/Admin/EditEdition/NewEdition";
import PrivateRoute from "./Components/PrivateRoute.js";
import Webdoc from "./Pages/Webdoc/Webdoc";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleVideoEnd = () => {
    navigate("/"); // Navegar para home após o vídeo
  };

  const onSendContent = (content) => {
    console.log("Conteúdo enviado:", content);
    // Aqui você pode implementar a lógica adicional para lidar com o conteúdo
  };

  useEffect(() => {
    if (location.pathname !== "/") {
    }
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/eye" element={<Eye />} />

        {/* Rotas de admin */}
        <Route path="/admin" element={<Login />} />
        <Route
          path="/admin/home"
          element={<PrivateRoute element={AdminHomePage} />}
        />
        <Route
          path="/admin/editor"
          element={<PrivateRoute element={TextEditor} />}
        />
        <Route
          path="/admin/manage-editions"
          element={
            <PrivateRoute
              element={() => <ManageEditions onSendContent={onSendContent} />}
            />
          }
        />
        <Route
          path="/admin/register"
          element={<PrivateRoute element={Register} />}
        />
        <Route
          path="/admin/users"
          element={<PrivateRoute element={ManageUsers} />}
        />
        <Route
          path="/admin/edit-edition/:id"
          element={<PrivateRoute element={EditEdition} />}
        />
        <Route
          path="/admin/add-edition/"
          element={<PrivateRoute element={NewEdition} />}
        />
        <Route path="/webdoc" element={<Webdoc />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
