import React, { useEffect, useRef, useState } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";
import camera from "./Assets/camera2.png";
import livro from "./Assets/livros.png";
import notas from "./Assets/notas.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";
import ImageGrid from "./grid";
import Edition from "./edition";
import images from "./Assets/imageImports";
import { useNavigate } from "react-router-dom";
import service from "../../service";
import MobileAnimatedPixels from "../MobilePixels/mobilePixels";

function Vertical2(props) {
  const elementRef = useRef(null);
  const sliderRef = useRef(null);
  const [currentEdition, setCurrentEdition] = useState(null);
  const [showImageGrid, setShowImageGrid] = useState(true);
  const [sliderIndex, setSliderIndex] = useState(0);
  const navigate = useNavigate(); // Hook de navegação
  const [isMobile, setIsMobile] = useState(false);
  const [showPixels, setShowPixels] = useState(false); // novo estado

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const verticalPaddingRef = useRef(null);

  useEffect(() => {
    const verticalPaddingEl = verticalPaddingRef.current;
    if (!verticalPaddingEl) return;

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = verticalPaddingEl;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // Quando chega ao final (usei -10 como margem de segurança)
        setShowPixels(true);
      } else {
        setShowPixels(false);
      }
    };

    verticalPaddingEl.addEventListener("scroll", onScroll);
    return () => {
      verticalPaddingEl.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Função auxiliar para gerar o array de imagens
  const generateImagesArray = (editionNumber, totalImages) => {
    return Array.from({ length: totalImages }, (_, index) => ({
      small: images[`edit${editionNumber}_${index + 1}_small`],
      large: images[`edit${editionNumber}_${index + 1}_large`],
    }));
  };
  const [editionData, setEditionData] = useState({});

  const fetchEditions = async () => {
    try {
      const editions = await service.getAllEditions();
      const data = {};

      for (const edition of editions.data) {
        data[edition.id] = {
          ...edition,
          images: await fetchEditionImages(edition.photoIds),
        };
      }
      setEditionData(data);
    } catch (error) {
      console.error("Erro ao carregar edições:", error);
    }
  };

  const fetchEditionImages = async (photoIds) => {
    const images = [];
    for (const photoId of photoIds) {
      try {
        const photoData = await service.getPhotoData(photoId);
        const photoUrl = service.getPhotoUrl(photoId);

        images.push({
          small: photoUrl,
          large: photoUrl,
          author: photoData.author,
          title: photoData.title, // Certifique-se de que o título da foto está sendo passado
          cover: photoData.cover, // Adiciona o campo cover
        });
      } catch (error) {
        console.error(`Erro ao carregar foto com ID ${photoId}:`, error);
      }
    }
    return images;
  };

  useEffect(() => {
    fetchEditions(); // Carrega as edições ao montar o componente
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${sliderIndex * 100}%)`;
    }
  }, [sliderIndex]);

  useEffect(() => {
    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  const handleViewMore = (edition) => {
    setShowImageGrid(false);
    setCurrentEdition(edition);
  };

  const handleBack = () => {
    setShowImageGrid(true);
    setCurrentEdition(null);
  };

  return (
    <div
      className={
        props.placeOn === "pagina-vertical-2"
          ? "vertical pagina-vertical-2 up"
          : "vertical pagina-vertical-2 down"
      }
    >
      <div className="vertical-left hide-on-mobile">
        <div className="menu-vertical-left">
          <a href="#historia">_Senta que lá vem a história</a>
          <a href="#edicoes-anteriores" onClick={handleBack}>
            _Edições Anteriores
          </a>
          <a href="#webdocumentario">_Webdocumentário</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });

            const scrollableElements = document.querySelectorAll("*");
            scrollableElements.forEach((element) => {
              if (element.scrollHeight > element.clientHeight) {
                element.scrollTo({ top: 0, behavior: "smooth" });
              }
            });
          }}
        ></div>
      </div>
      <div className="vertical-right">
        <div className="vertical-padding">
          {props.textinhos &&
            props.textinhos.map(
              (t, i) =>
                t.sectionId === 2 && (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "historia" && (
                        <div className="images-align-mobile">
                          <img
                            alt="livros"
                            src={livro}
                            className="float-right vertical-logo"
                          />
                          <img
                            alt="bloco de notas"
                            src={notas}
                            className="float-left vertical-notas"
                          />
                        </div>
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    </div>
                  </div>
                )
            )}

          <div
            id="edicoes-anteriores"
            style={{ color: "var(--roxo)" }}
            className="vertical-title bg-branco"
          >
            Edições Anteriores
          </div>
          {showImageGrid ? (
            <ImageGrid onViewMore={handleViewMore} editionData={editionData} />
          ) : (
            currentEdition && (
              <Edition
                edition={currentEdition}
                images={editionData[currentEdition].images}
                titles={editionData[currentEdition]?.titles}
                description={editionData[currentEdition].description}
                signatures={editionData[currentEdition].signatures}
                onBack={handleBack}
                showBackButton={true}
                title={editionData[currentEdition].title} // Passa o título da edição
              />
            )
          )}

          <div
            id="webdocumentario"
            style={{ color: "#5c1c74", backgroundColor: "white" }}
            className="vertical-title"
          >
            Webdocumentário
          </div>
          <button
            onClick={() => navigate("/webdoc")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
              textDecoration: "underline",
              color: "inherit",
            }}
          >
            <img
              alt="camera"
              src={camera}
              className="float-left vertical-logo"
            />
          </button>
          <div
            style={{ marginTop: 100 }}
            className="seta-pro-topo-mobile"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });

              const scrollableElements = document.querySelectorAll("*");
              scrollableElements.forEach((element) => {
                if (element.scrollHeight > element.clientHeight) {
                  element.scrollTo({ top: 0, behavior: "smooth" });
                }
              });
            }}
          ></div>
          {isMobile ? (
            <MobileAnimatedPixels amount={100} />
          ) : (
            <AnimatedPixels amount={100} />
          )}
        </div>
      </div>
      <SidebarDireita bg="var(--rosa)" />
    </div>
  );
}

export default Vertical2;
