import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import "./vertical.scss";
import filme from "./Assets/filme.png";
import service from "../../service";

// Componente LazyImage com Intersection Observer
const LazyImage = ({ src, alt, onClick, className, style }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    let observer;
    if (imgRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect();
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(imgRef.current);
    }
    return () => {
      if (observer && observer.disconnect) observer.disconnect();
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : undefined}
      alt={alt}
      onClick={onClick}
      className={className}
      style={style}
      loading="lazy"
    />
  );
};

// Memoização do componente ImageItem
const ImageItem = memo(
  ({ imageObj, title, onClick, style, isSuperior, imageIndex }) => (
    <>
      <div
        className={`titulo-${isSuperior ? "superior" : "inferior"}`}
        style={{ left: calculateImagePosition(imageIndex) }}
      >
        {title}
      </div>
      <LazyImage
        src={imageObj.small}
        alt={title}
        onClick={onClick}
        className="imagem-posicionada-edition lazy-image"
        style={{ left: calculateImagePosition(imageIndex) }}
      />
    </>
  )
);

const calculateImagePosition = (index) => {
  const positions = ["0.9%", "25.6%", "50.4%", "75.2%"];
  return positions[index] || "0%";
};

const Edition = ({
  edition,
  images,
  description,
  signatures,
  onBack,
  showBackButton,
  titles = [],
  title,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [showFullGallery, setShowFullGallery] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverDuration, setHoverDuration] = useState(0);
  const hoverTimerRef = useRef(null);
  const [author, setAuthor] = useState(null);
  const [photoTitle, setPhotoTitle] = useState(null); // Novo estado para o título da foto
  const [editionContent, setEditionContent] = useState("");

  // Filtra as imagens com cover == 0
  const filteredImages = images.filter((image) => image.cover == 0);

  const imagesPerSlide = 4;
  const groupedImages = [];
  for (let i = 0; i < filteredImages.length; i += imagesPerSlide) {
    groupedImages.push(filteredImages.slice(i, i + imagesPerSlide));
  }

  const totalSlides = groupedImages.length;

  const nextSlide = useCallback(
    () => setSliderIndex((prevIndex) => (prevIndex + 1) % totalSlides),
    [totalSlides]
  );

  const prevSlide = useCallback(
    () =>
      setSliderIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides),
    [totalSlides]
  );

  useEffect(() => {
    let interval;

    if (!isHovered) {
      if (hoverDuration >= 5000) {
        interval = setTimeout(nextSlide, 1000);
      } else {
        interval = setInterval(nextSlide, 5000);
      }
    }
    return () => {
      clearInterval(interval);
      clearTimeout(interval);
    };
  }, [isHovered, hoverDuration, nextSlide]);

  useEffect(() => {
    const fetchEditionContent = async () => {
      try {
        const response = await service.getEditionById(edition);
        setEditionContent(response.innerHTML);
      } catch (error) {
        console.error("Erro ao buscar conteúdo da edição:", error);
      }
    };
    fetchEditionContent();
  }, [edition]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    hoverTimerRef.current = setInterval(() => {
      setHoverDuration((prevDuration) => prevDuration + 1000);
    }, 1000);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    clearInterval(hoverTimerRef.current);

    if (hoverDuration < 5000) {
      setHoverDuration(0);
    }
  };

  const openImageCarousel = useCallback(
    (index) => {
      setSelectedImageIndex(index);
      setSelectedImage(filteredImages[index].large);
      setAuthor(filteredImages[index].author);
      setPhotoTitle(filteredImages[index].title); // Armazena o título da foto

      const nextIndex = (index + 1) % filteredImages.length;
      const prevIndex = (index - 1 + filteredImages.length) % filteredImages.length;

      const preloadNextImage = new Image();
      preloadNextImage.src = filteredImages[nextIndex].large;

      const preloadPrevImage = new Image();
      preloadPrevImage.src = filteredImages[prevIndex].large;
    },
    [filteredImages]
  );

  const nextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % filteredImages.length;
      setSelectedImage(filteredImages[newIndex].large);
      setAuthor(filteredImages[newIndex].author);
      setPhotoTitle(filteredImages[newIndex].title); // Atualiza o título da foto

      const nextNextIndex = (newIndex + 1) % filteredImages.length;
      const preloadNextImage = new Image();
      preloadNextImage.src = filteredImages[nextNextIndex].large;

      return newIndex;
    });
  }, [filteredImages]);

  const previousImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + filteredImages.length) % filteredImages.length;
      setSelectedImage(filteredImages[newIndex].large);
      setAuthor(filteredImages[newIndex].author);
      setPhotoTitle(filteredImages[newIndex].title); // Atualiza o título da foto

      const prevPrevIndex = (newIndex - 1 + filteredImages.length) % filteredImages.length;
      const preloadPrevImage = new Image();
      preloadPrevImage.src = filteredImages[prevPrevIndex].large;

      return newIndex;
    });
  }, [filteredImages]);

  const toggleFullInfo = () => setShowFullInfo(!showFullInfo);
  const toggleFullGallery = () => setShowFullGallery(!showFullGallery);
  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageIndex(null);
    setAuthor(null);
    setPhotoTitle(null); // Limpa o título da foto ao fechar o modal
  };

  return (
    <div className="edition-container">
      <div className="vertical-title bg-azul">
        <h3 style={{ color: "black" }}>{title}</h3>
      </div>

      {!showFullGallery && (
        <>
          <div
            className="slider-wrapper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="slider-container"
              style={{ transform: `translateX(-${sliderIndex * 100}%)` }}
            >
              {groupedImages.map((group, slideIndex) => (
                <div className="filme-container" key={slideIndex}>
                  {group.map((imageObj, imageIndex) => {
                    const globalImageIndex = imageIndex + slideIndex * imagesPerSlide;
                    const title = imageObj.author || `Imagem ${globalImageIndex + 1}`;
                    const isSuperior = globalImageIndex % 2 === 0;

                    return (
                      <ImageItem
                        key={globalImageIndex}
                        imageObj={imageObj}
                        title={title}
                        onClick={() => openImageCarousel(globalImageIndex)}
                        isSuperior={isSuperior}
                        imageIndex={imageIndex}
                      />
                    );
                  })}
                  <img
                    src={filme}
                    alt="Fundo do filme"
                    className="filme-preto"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>

            <button className="slider-button left" onClick={prevSlide}>
              {"<"}
            </button>
            <button className="slider-button right" onClick={nextSlide}>
              {">"}
            </button>
          </div>

          <div className="edition-info-container">
            <div className={`edition-info ${showFullInfo ? "expanded" : "collapsed"}`}>
              <p dangerouslySetInnerHTML={{ __html: editionContent }}></p>

              {signatures && (
                <div className="signatures">
                  {signatures.map((sig, index) => (
                    <div className="title-name-container" key={index}>
                      <h3>{sig.title}</h3>
                      <p>{sig.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="buttons-container">
              <button onClick={toggleFullInfo} className="toggle-button">
                {showFullInfo ? "Ver menos" : "Ver mais"}
              </button>
              <button onClick={toggleFullGallery} className="toggle-button">
                {showFullGallery ? "Ocultar galeria completa" : "Ver galeria completa"}
              </button>
            </div>
          </div>
        </>
      )}

      {showFullGallery && (
        <div className="gallery-container">
          {groupedImages.map((group, groupIndex) => (
            <div className="filme-container" key={groupIndex}>
              {group.map((imageObj, imageIndex) => {
                const globalImageIndex = imageIndex + groupIndex * imagesPerSlide;
                const title = imageObj.author || `Imagem ${globalImageIndex + 1}`;
                const isSuperior = globalImageIndex % 2 === 0;

                return (
                  <ImageItem
                    key={globalImageIndex}
                    imageObj={imageObj}
                    title={title}
                    onClick={() => openImageCarousel(globalImageIndex)}
                    isSuperior={isSuperior}
                    imageIndex={imageIndex}
                  />
                );
              })}
              <img
                src={filme}
                alt="Fundo do filme"
                className="filme-preto"
                loading="lazy"
              />
            </div>
          ))}
          <div className="buttons-container">
            <button onClick={toggleFullGallery} className="toggle-button">
              Ocultar galeria completa
            </button>
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              ×
            </button>

            <button
              className="modal-nav-button left"
              onClick={(e) => {
                e.stopPropagation();
                previousImage();
              }}
            >
              {"<"}
            </button>

            <div className="modal-image-title-container">
              <div className="modal-image-container">
                <img
                  src={selectedImage}
                  alt="Imagem ampliada"
                  className="modal-image"
                />
              </div>

              <div className="modal-title-container">
                <h3 className="photo-title">{photoTitle}</h3> {/* Título da foto */}
                <h3 className="author-title">{author}</h3> {/* Autor da foto */}
              </div>
            </div>

            <button
              className="modal-nav-button right"
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
            >
              {">"}
            </button>
          </div>
        </div>
      )}

      {showBackButton && (
        <button onClick={onBack} className="back-button">
          Voltar
        </button>
      )}
    </div>
  );
};

export default Edition;