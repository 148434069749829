// src/components/Section/Section.jsx
import "./section.scss";
import React, { useEffect, useState } from "react";
import menuMobileImage from "./Assets/menu-mobile.png";
import Marquee from "react-easy-marquee";

import role from "./Assets/nosso-role.png";
import ciclo from "./Assets/ciclo.png";
import chega from "./Assets/chega-mais.png";

import subAmarelo from "./Assets/sub-amarelo.png";
import subAzul from "./Assets/sub-azul.png";
import subRosa from "./Assets/sub-rosa.png";
import roxaFita from "./Assets/roxa.png";

import { useInView } from "react-intersection-observer";

import { TypeAnimation } from "react-type-animation";

function Section(props) {
  const [verdeEntrou, setVerdeEntrou] = useState(0);
  const [rosaEntrou, setRosaEntrou] = useState(0);
  const [azulEntrou, setAzulEntrou] = useState(0);
  const [typing, setTyping] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { ref, inView } = useInView({
    threshold: 0.7,
  });

  useEffect(() => {
    const element = document.querySelector(".letreiro-mobile");
    if (element) {
      element.style.overflow = "visible";
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (inView === true) {
      if (props.bg === "var(--verde)") {
        if (verdeEntrou < 1) {
          let temp = verdeEntrou + 1;
          setVerdeEntrou(temp);

          setTimeout(function () {
            //Start the timer
            setTyping(true); //After 1 second, set render to true
          }, 400);
        }
        props.setSecondaryColor("var(--rosa)");
        props.setSecondaryIconColor("var(--rosa)");
      } else if (props.bg === "var(--rosa)") {
        if (rosaEntrou < 1) {
          let temp = rosaEntrou + 1;
          setRosaEntrou(temp);
        }
        props.setSecondaryColor("var(--azul)");
        props.setSecondaryIconColor("var(--azul)");
      } else if (props.bg === "var(--azul)") {
        if (azulEntrou < 1) {
          let temp = azulEntrou + 1;
          setAzulEntrou(temp);
        }
        props.setSecondaryColor("var(--roxo)");
        props.setSecondaryIconColor("var(--roxo)");
      }
    }
  }, [inView, azulEntrou, props, rosaEntrou, verdeEntrou]);

  function setPlace(section, position, pagina, color) {
    props.scrollToSection(section, pagina);
    props.setPosition(position);
    setTimeout(function () {
      props.setSecondaryIconColor("var(--" + color + ")");
    }, 2300);
  }

  return (
    <section
      ref={ref}
      style={{ backgroundColor: props.bg }}
      className={
        props.bg === "var(--azul)"
          ? "bg-img-azul"
          : props.bg === "var(--rosa)"
          ? "bg-img-rosa"
          : "bg-img-verde"
      }
    >
      {/*  -----------------------  PRIMEIRA SEÇÃO  ------------------------   */}
      {props.letreiro === "role" && (
        <>
          {isMobile ? (
            <div className="letreiro-mobile-container">
              <div className="letreiro-mobile">
                <img
                  alt="Nosso rolê"
                  src={role}
                  className="imagem mobile-animacao"
                />
              </div>
            </div>
          ) : (
            <div className="letreiro">
              <Marquee duration={15000} axis="y" height="100%">
                <img alt="Nosso rolê" src={role} className="imagem" />
              </Marquee>
            </div>
          )}

          <div className="meio">
            <div className="titulo">
              <img
                alt="O que é ECOFOTO?"
                src={subAmarelo}
                className={verdeEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div
              className="div-texto div-txt-verde"
              style={isMobile ? { marginTop: "-6vw" } : {}}
            >
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "nosso-role") {
                    return (
                      <div
                        key={i}
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>

          {/* DIREITA DIV */}
          {isMobile ? (
            // New Mobile Version
            <>
              <div className="mobile-menu-container1">
                <div className="mobile-menu-item">
                  <a
                    href="#sobre-nos"
                    onClick={() =>
                      setPlace("primeiro", "sobre-nos", "pagina-vertical-1")
                    }
                  >
                    <TypeAnimation sequence={["_Sobre nós"]} speed={10} />
                  </a>
                </div>
                <div className="mobile-menu-item">
                  <a
                    href="#o-ciclo"
                    onClick={() =>
                      setPlace("primeiro", "o-ciclo", "pagina-vertical-1")
                    }
                  >
                    <TypeAnimation
                      sequence={[" _Ciclo de Criação"]}
                      speed={10}
                    />
                  </a>
                </div>
              </div>

              <div
                className="mobile-menu-container11"
                
              >
                <div className="mobile-menu-item11">
                  <a
                    href="#nossa-galera"
                    onClick={() =>
                      setPlace("primeiro", "nossa-galera", "pagina-vertical-1")
                    }
                  >
                    <TypeAnimation sequence={["_Nossa Galera"]} speed={10} />
                  </a>
                </div>
                <div
                  className="mobile-menu-item11"
        
                >
                  <a
                    href="#como-faz"
                    onClick={() =>
                      setPlace("primeiro", "como-faz", "pagina-vertical-1")
                    }
                  >
                    <TypeAnimation sequence={["_Como atuamos?"]} speed={10} />
                  </a>
                </div>
              </div>
            </>
          ) : (
            // Desktop Version remains unchanged
            <div className="direita">
              <div className={verdeEntrou >= 1 ? "mao" : ""}>
                {typing === true && (
                  <>
                    <div
                      className={verdeEntrou >= 1 ? "texto-mao mao-1" : "hide"}
                    >
                      <a
                        href="#sobre-nos"
                        className="no-underline"
                        onClick={() =>
                          setPlace("primeiro", "sobre-nos", "pagina-vertical-1")
                        }
                      >
                        <TypeAnimation sequence={["_Sobre nós"]} speed={10} />
                      </a>
                    </div>
                    <div
                      className={verdeEntrou >= 1 ? "texto-mao mao-2" : "hide"}
                    >
                      <a
                        href="#o-ciclo"
                        className="no-underline"
                        onClick={() =>
                          setPlace("primeiro", "o-ciclo", "pagina-vertical-1")
                        }
                      >
                        <TypeAnimation
                          sequence={[" _Ciclo de criação"]}
                          speed={10}
                        />
                      </a>
                    </div>
                    <div
                      className={verdeEntrou >= 1 ? "texto-mao mao-3" : "hide"}
                    >
                      <a
                        href="#como-faz"
                        className="no-underline"
                        onClick={() =>
                          setPlace("primeiro", "como-faz", "pagina-vertical-1")
                        }
                      >
                        <TypeAnimation
                          sequence={["_Como faz pra realizar tudo isso?"]}
                          speed={10}
                        />
                      </a>
                    </div>
                    <div
                      className={verdeEntrou >= 1 ? "texto-mao mao-4" : "hide"}
                    >
                      <a
                        href="#nossa-galera"
                        className="no-underline"
                        onClick={() =>
                          setPlace(
                            "primeiro",
                            "nossa-galera",
                            "pagina-vertical-1"
                          )
                        }
                      >
                        <TypeAnimation
                          sequence={[" _Nossa galera"]}
                          speed={10}
                        />
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {/*  -----------------------  SEGUNDA SEÇÃO  ------------------------   */}
      {props.letreiro === "ciclo" && (
        <>
          {isMobile ? (
            <div className="letreiro-mobile-container">
              <div className="letreiro-mobile">
                <img
                  alt="ciclo"
                  src={ciclo}
                  className="imagem mobile-animacao"
                />
              </div>
            </div>
          ) : (
            <div className="letreiro">
              <Marquee duration={15000} axis="y" height="100%">
                <img alt="ciclo" src={ciclo} className="imagem" />
              </Marquee>
            </div>
          )}
          <div className="meio">
            <div className="titulo">
              <img
                alt="Uma imersão artística"
                src={subRosa}
                className={rosaEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div
              className="div-texto div-txt-rosa"
              style={isMobile ? { marginTop: "4vw" } : {}}
            >
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "ciclo-criacao") {
                    return (
                      <div
                        key={i}
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>

          {/* DIREITA DIV */}
          {isMobile ? (
            // New Mobile Version
            <div className="mobile-menu-container2">
              <a href="#historia"
                onClick={() => {
                  setPlace("seg", "historia", "pagina-vertical-2", "rosa");
                  window.location.href = "#historia";
                }}
                className={isMobile || rosaEntrou >= 1 ? "polaroid-topo" : ""} // Modified condition
              ></a>
              <a href="#edicoes-anteriores"
                onClick={() => {
                  setPlace(
                    "seg",
                    "edicoes-anteriores",
                    "pagina-vertical-2",
                    "rosa"
                  );
                  window.location.href = "#edicoes-anteriores";
                }}
                className={isMobile || rosaEntrou >= 1 ? "polaroid-meio" : ""} // Modified condition
              ></a>
              <a href="#webdocumentario"
                onClick={() => {
                  setPlace(
                    "seg",
                    "webdocumentario",
                    "pagina-vertical-2",
                    "rosa"
                  );
                  window.location.href = "#webdocumentario";
                }}
                className={isMobile || rosaEntrou >= 1 ? "polaroid-baixo" : ""} // Modified condition
              ></a>
            </div>
          ) : (
            // Desktop Version remains unchanged
            <div className="direita">
              <div className={rosaEntrou >= 1 ? "direita-polaroid" : ""}>
                <div
                  onClick={() => {
                    setPlace("seg", "historia", "pagina-vertical-2", "rosa");
                    window.location.href = "#historia";
                  }}
                  className={isMobile || rosaEntrou >= 1 ? "polaroid-topo" : ""} // Modified condition
                ></div>
                <div
                  onClick={() => {
                    setPlace(
                      "seg",
                      "edicoes-anteriores",
                      "pagina-vertical-2",
                      "rosa"
                    );
                    window.location.href = "#edicoes-anteriores";
                  }}
                  className={isMobile || rosaEntrou >= 1 ? "polaroid-meio" : ""} // Modified condition
                ></div>
                <div
                  onClick={() => {
                    setPlace(
                      "seg",
                      "webdocumentario",
                      "pagina-vertical-2",
                      "rosa"
                    );
                    window.location.href = "#webdocumentario";
                  }}
                  className={
                    isMobile || rosaEntrou >= 1 ? "polaroid-baixo" : ""
                  } // Modified condition
                ></div>
              </div>
            </div>
          )}
        </>
      )}

      {/*  -----------------------  TERCEIRA SEÇÃO  ------------------------   */}
      {props.letreiro === "chega" && (
  <>
    {isMobile ? (
      <>
        <div className="letreiro-mobile-container">
          <div className="letreiro-mobile">
            <img
              alt="chega"
              src={chega}
              className="imagem mobile-animacao"
            />
          </div>
        </div>
        
        <div className="meio">
          <div className="titulo">
            <img
              alt="Como ajudar o projeto?"
              src={subAzul}
              className={azulEntrou >= 1 ? "entrou-na-tela" : ""}
            />
          </div>
          <div className="div-texto div-txt-azul">
            {props.textinhos &&
              props.textinhos.map((t, i) => {
                if (t.tagId === "chega-mais") {
                  return (
                    <div
                      key={i}
                      className={`texto ${props.secondary}`}
                      dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>

        {/* Novo Menu Mobile Simplificado */}
        <div className="mobile-menu-chega">
      <div className="fita-roxa-coluna">
        <a
          href="#fortalecendo"
          onClick={() => setPlace("ter", "fortalecendo", "pagina-vertical-3")}
        >
          Galera que quer fortalecer no amor
        </a>
        <a
          href="#apoio"
          onClick={() => setPlace("ter", "apoio", "pagina-vertical-3")}
        >
          Galera que quer apoiar com algum serviço
        </a>
      </div>

      <div className="fita-roxa-coluna">
        <a
          href="#big-companies"
          onClick={() => setPlace("ter", "big-companies", "pagina-vertical-3")}
        >
          Big companies
        </a>
        <a
          href="#me-liga"
          onClick={() => setPlace("ter", "me-liga", "pagina-vertical-3")}
        >
          Me liga, me manda um telegrama...
        </a>
      </div>
    </div>
      </>
    ) : (
      // Lógica para desktop permanece inalterada
      <> 
      <div className="letreiro">
      <Marquee duration={15000} axis="y" height="100%">
        <img alt="chega" src={chega} className="imagem" />
      </Marquee>
    </div>
    <div className="meio">
            <div className="titulo">
              <img
                alt="Como ajudar o projeto?"
                src={subAzul}
                className={azulEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div className="div-texto div-txt-azul">
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "chega-mais") {
                    return (
                      <div
                        key={i}
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
      <div className="direita">
        <div className={azulEntrou >= 1 ? "fita-direita" : ""}>
          <div
            onClick={() => {
              setPlace("ter", "fortalecendo", "pagina-vertical-3", "azul");
              window.location.href = "#fortalecendo";
            }}
            className={isMobile || azulEntrou >= 1 ? "fita-direita-1" : ""}
          ></div>
          <div
            onClick={() => {
              setPlace("ter", "apoio", "pagina-vertical-3", "azul");
              window.location.href = "#apoio";
            }}
            className={isMobile || azulEntrou >= 1 ? "fita-direita-2" : ""}
          ></div>
          <div
            onClick={() => {
              setPlace("ter", "big-companies", "pagina-vertical-3", "azul");
              window.location.href = "#big-companies";
            }}
            className={isMobile || azulEntrou >= 1 ? "fita-direita-3" : ""}
          ></div>
          <div
            onClick={() => {
              setPlace("ter", "me-liga", "pagina-vertical-3", "azul");
              window.location.href = "#me-liga";
            }}
            className={isMobile || azulEntrou >= 1 ? "fita-direita-4" : ""}
          ></div>
        </div>
      </div>
      </>
    )}
  </>
)}

    </section>
  );
}

export default Section;